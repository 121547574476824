import LandingHeader from "../components/header";
import FooterSection from "../components/footer-section";
import { useQuery } from "../hooks/useQuery";

function VerificationPage() {
  const query = useQuery();
  const code = query.get("verificationCode");
  return (
    <>
      <LandingHeader showLinks={false} />
      <div className="main-content">
        <div className="container">
          <div className="row">
            {/* TODO: add another H2 for mobile view only [open churchplan] */}
            <h2
              style={{
                margin: "2rem 0 3rem 0",
              }}
            >
              Thank you for verifying your email address.
            </h2>
            <h4>Please use the following 4 digits code in the mobile app</h4>
            <h1
              style={{
                margin: "3rem auto",
                "letter-spacing": "1rem",
              }}
            >
              {code}
            </h1>
            <h3 className="my-3">
              Download Church<span className="text-primary">Plan</span> Mobile
              App
            </h3>
            <div
              className="container-fluid"
              style={{
                maxWidth: "24rem",
                margin: "2rem auto",
              }}
            >
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  <a
                    href="https://apps.apple.com/us/app/churchplan/id1566418629?itsct=apps_box_badge&amp;itscg=30200"
                    style={{
                      width: "160px",
                    }}
                  >
                    <img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1636761600&h=d4339bf0d19b0325f888c96acffe9205"
                      alt="Download on the App Store"
                    />
                  </a>
                </div>
                <div className="col-sm-12 col-md-6">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.churchplan"
                    style={{
                      width: "160px",
                    }}
                  >
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </>
  );
}

export default VerificationPage;
