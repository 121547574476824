export function ChurchCard({
  logo = "https://media.istockphoto.com/photos/old-white-church-picture-id157610088?k=20&m=157610088&s=612x612&w=0&h=HEdl0g6XUwtHjSS2nh9ey7rN0a88TT-BEhZ_FK61I0I=",
  name = "Church Name",
}) {
  return (
    <div className="church-card ">
      <img
        className="church-card-logo"
        src="images/logo/logo_inverse.svg"
        alt="Card cap"
      />

      <div
        style={{
          backgroundImage: `url(${logo})`,
        }}
        className="d-flex flex-column justify-content-end church-card-body"
      >
        <div className="church-card-details">
          <h5 className="my-2 church-card-inner-text">{name}</h5>
        </div>
      </div>
      <h4 className="my-4 church-card-outer-text">{name}</h4>
    </div>
  );
}
