import React, { useEffect, useState } from "react";
import { ChurchCard } from "./church-card";
import { useQuery } from "../../hooks/useQuery";
import { getChurchDetails } from "../../services/signup.service";

export function CreateAccount() {
  let query = useQuery();
  const [church, setChurch] = useState(null);
  useEffect(() => {
    getChurchDetails(query.get("church"))
      .then((res) => {
        setChurch(res.data.church);
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
      });
  }, [query]);
  return (
    <div className="row">
      <div className="col-md-4 col-sm-12 pt-5 d-flex flex-column align-items-center">
        <h2 className="py-3 create-account-text">Create Account</h2>
        <ChurchCard name={church?.name} />
      </div>
      <div className=" col-md-8 pt-5 align-items-center justify-content-center text-center">
        <h4 className="my-5">
          To Join {church?.name} on the ChurchPlan, please download our app.
        </h4>
        <div className="d-flex justify-content-center align-items-center">
          <div className="flex-1">
            <a
              href="https://apps.apple.com/eg/app/churchplan/id1566418629"
              target="_blank"
            >
              <img
                width="250"
                className="mx-2"
                src="/images/icons/app-store.svg"
                alt="phone icon"
              />
            </a>
          </div>
          <div className="flex-1">
            <a
              href="https://play.google.com/store/apps/details?id=com.churchplan"
              target="_blank"
            >
              <img
                width="350"
                className="mx-2"
                src="/images/icons/playstore.svg"
                alt="phone icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
