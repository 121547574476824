import { useState } from "react";
import { useQuery } from "../../hooks/useQuery";
import { InputCode } from "../input-code";
import {
  verifyCode,
  resendVerificationCode,
} from "../../services/signup.service";
import { useHistory } from "react-router-dom";

export function Verify() {
  const [loading, setLoading] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [reloadInput, setReloadInput] = useState(false);
  let query = useQuery();
  const history = useHistory();

  const submitCode = (code) => {
    setLoading(true);
    let email = query.get("email");
    let church = query.get("church");
    verifyCode({
      verificationCode: code,
      email: email,
      church: church,
    })
      .then((res) => {
        history.push(`/signup/terms`);
      })
      .catch((err) => {
        setVerificationError(true);
        setReloadInput(!reloadInput);
        setLoading(false);
      });
  };
  const resendCode = () => {
    setLoading(true);
    let email = query.get("email");
    let church = query.get("church");
    resendVerificationCode({
      email: email,
      church: church,
    })
      .then(() => {
        alert("New verification email with code has been sent");
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
      });
  };
  return (
    <div className="col-md-8 col-sm-12">
      <section className="text-black">
        <div>
          <div className="mb-xl">
            <div className="row signup-form">
              <p
                className="col-md-12 col-sm-12 text-center
                "
                style={{
                  fontSize: "1.2rem",
                }}
              >
                A message with a Verification <br />
                code has been sent to you.
                <br /> Enter the code to continue
              </p>
              <span className="col-md-12 text-center py-4 text-primary">
                {verificationError && "Invalid code, please try again"}
              </span>
              <div className="col-md-12 col-sm-12">
                <InputCode
                  length={4}
                  label="Code Label"
                  loading={loading}
                  onComplete={submitCode}
                  reloadInput={reloadInput}
                />
              </div>
              <span className="col-md-12 text-center mt-4 ">
                Did not get a verification code?
              </span>
              <span
                className="col-md-12 text-center text-primary "
                style={{
                  cursor: "default",
                }}
                onClick={resendCode}
              >
                Resend code
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
