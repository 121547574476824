import React, { Component } from "react";
import * as axios from "axios";

export default class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      name: "",
      email: "",
      loading: false,
      isError: {
        name: "",
        email: "",
        subject: ""
      }
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/contact-us`, {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        subject: this.state.subject,
        feedback_type: "feedback"
      })
      .then((res) => {
        this.setState({
          submitted: true,
          loading: false
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          submitted: false,
          loading: false
        });
      });
  };

  formValChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...this.state.isError };

    switch (name) {
      case "name":
        isError.name = value.length < 4 ? "Atleast 4 characaters required" : "";
        break;

      default:
        break;
    }

    this.setState({
      isError,
      [name]: value
    });
  };

  render() {
    return (
      <section id="stripe-page" className="hero-area text-black py-4">
        <div id="contact section" className="py-5">
          <div className="container py-3">
            <div className="row">
              {/* Contact Form */}
              <div className="col-md-6 col-sm-12">
                <h3 className="text-left my-3">Contact Us</h3>
                {!this.state.submitted ? (
                  <form
                    disabled={this.state.loading}
                    style={{
                      paddingRight: "5rem"
                    }}
                    onSubmit={this.onSubmit}
                    className="row"
                  >
                    <div className="col-6 form-group my-2">
                      <label className="text-left  my-1">Your Name</label>
                      <input
                        className="form-control"
                        onChange={this.formValChange}
                        placeholder="John"
                        name="name"
                      />
                    </div>
                    <div className="col-6 form-group my-2">
                      <label className="text-left  my-1">
                        Your Email Address
                      </label>
                      <input
                        onChange={this.formValChange}
                        className="form-control"
                        placeholder="xx@gmail.com"
                        name="email"
                      />
                    </div>
                    <div className="col-md-12 form-group my-2">
                      <label className="text-left  my-1">Subject</label>
                      <input
                        onChange={this.formValChange}
                        className="form-control"
                        placeholder="Inquiry"
                        name="subject"
                      />
                    </div>
                    <div className="col-md-12 my-2 form-group my-2">
                      <label className="text-left  my-1">Message/Inquiry</label>
                      <textarea
                        rows="5"
                        onChange={this.formValChange}
                        className="form-control"
                        name="message"
                        placeholder="Inquiry message"
                      ></textarea>
                    </div>
                    <div className="col-md-12 text-right my-2">
                      <input
                        style={{
                          borderRadius: 20
                        }}
                        className="btn btn-primary px-3"
                        type="submit"
                        value="Submit"
                      />
                    </div>
                  </form>
                ) : (
                  <div className="container">Thank you!</div>
                )}
              </div>
              {/* Contact Info */}
              <div className="col-md-6 text-left px-5 contact-info">
                <div className="mx-3">
                  <h3 className="text-left my-3">Phone & Email</h3>
                  <div className="py-2">
                    <div className="my-3">
                      <img
                        width="15"
                        className="mx-2 primary-svg"
                        src="/images/icons/phone-solid.svg"
                        alt="phone icon"
                      />

                      <label className="text-bold">Phone:</label>
                      <span className="mx-2">1(800)420-1140</span>
                    </div>
                    <div className="my-3">
                      <img
                        width="15"
                        className="mx-2 primary-svg"
                        src="/images/icons/envelope-solid.svg"
                        alt="mail icon"
                      />
                      <i className="fas fa-envelope"></i>
                      <label className="text-bold">Email:</label>
                      <span className="mx-2">info@churchplan.org</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
