import { scroller } from "react-scroll";

import { NavHashLink } from "react-router-hash-link";

const links = [
  {
    label: "Overview",
    href: "/home#overview",
    hash: true,
    hashPath: "overview",
  },
  {
    label: "Features",
    href: "/home#features",
    hash: true,
    hashPath: "features",
  },
  {
    label: "Packages",
    href: "/packages",
  },
  {
    label: "Connect Stripe account",
    href: "/payment",
  },
  {
    label: "Contact",
    href: "/contact",
  },
  {
    label: "Help",
    href: "/help",
  },
  {
    label: "Login",
    href: "https://admin.churchplan.org",
    target: "_blank",
  },
];

const scrollToSection = (sectionSelector) => {
  scroller.scrollTo(sectionSelector, {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
  });
};

function LandingHeader({ showLinks = true }) {
  return (
    <header className='header navbar-area'>
      <div style={{ maskImage: "url('/images/bg-dark.png')" }}>
        <div
          style={{
            backgroundImage: "url('/images/header_bg.png')",
          }}
          className='logo-wrapper'
        >
          <div className='container'>
            <div className='row align-items-center justify-content-center '>
              <div className='col-lg-12 col-sm-6'>
                <a
                  className='navbar-brand d-flex flex-column align-items-center py-2 no-margin'
                  href='/'
                >
                  <img
                    style={{ height: 80 }}
                    className='header-logo my-1'
                    src='/images/logo/logo.png'
                    alt='Logo'
                  />
                  <img
                    height={20}
                    className='my-3'
                    style={{ height: 20 }}
                    src='/images/logo/logo_text.png'
                    alt='LogoText'
                  />
                </a>
              </div>
              {showLinks && (
                <button
                  className='navbar-toggler mobile-menu-btn'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                >
                  <span className='toggler-icon'></span>
                  <span className='toggler-icon'></span>
                  <span className='toggler-icon'></span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showLinks && (
        <div className='links-wrapper'>
          <div className='container '>
            <div className='row align-items-center'>
              <div className='col-lg-12'>
                <div className='nav-inner'>
                  <nav className='navbar navbar-expand-lg'>
                    <div
                      className='collapse navbar-collapse sub-menu-bar'
                      id='navbarSupportedContent'
                    >
                      <ul id='nav' className='navbar-nav ms-auto'>
                        {links.map((x) => {
                          return (
                            <li key={x.href} className='nav-item'>
                              {x.target ? (
                                <a
                                  target={x.target}
                                  href={x.href}
                                  onClick={scrollToSection(x.href)}
                                  className='text-black nav-link py-3'
                                  aria-label='Toggle navigation'
                                >
                                  {x.label}
                                </a>
                              ) : (
                                <NavHashLink
                                  smooth
                                  target={x.target}
                                  activeClassName='is-active'
                                  to={x.href}
                                  onClick={scrollToSection(x.href)}
                                  className='text-black nav-link py-3'
                                  aria-label='Toggle navigation'
                                >
                                  {x.label}
                                </NavHashLink>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default LandingHeader;
