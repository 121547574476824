import LandingHeader from "../components/header";
import MainBanner from "../components/main-banner";
import FooterSection from "../components/footer-section";
import { Switch, Route, Redirect } from "react-router-dom";
import ContactPage from "./contact";
import ComingSoon from "../components/coming";
import { TermsConditions } from "./terms_conditions";
import PrivacyPolicy from "./privacy";
import LicenseAgreement from "./license_agreement";
import PaymentGateway from "./payment-gatewat";

function Home() {
  return (
    <div className="Home">
      {LandingHeader({ showLinks: true })}
      <div className="main-content">
        <Switch>
          <Route path={`/contact`}>
            <ContactPage />
          </Route>
          <Route path={`/home`}>{MainBanner()}</Route>
          <Route path={`/terms_and_conditions`}>{TermsConditions()}</Route>
          <Route path={`/privacy-policy`}>{PrivacyPolicy()}</Route>
          <Route path={`/license-agreement`}>{LicenseAgreement()}</Route>
          <Route path={`/packages`}>{ComingSoon()}</Route>
          <Route path={`/payment`}>{PaymentGateway()}</Route>
          <Route path={`/help`}>{ComingSoon()}</Route>
          <Route
            path="/"
            render={() => {
              return <Redirect to="/home" />;
            }}
          />
        </Switch>
      </div>

      {FooterSection()}
    </div>
  );
}

export default Home;
