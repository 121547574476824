import "./App.css";
import "./custom.scss";
import { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignUpPage from "./pages/signup";
import VerifyCodePage from "./pages/verification";
import Home from "./pages/home";

class App extends Component {
  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    return (
      <div className="App">
        <Router>
          <div className="preloader">
            <div className="preloader-inner">
              <div className="preloader-icon">
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <Switch>
            <Route key="signup" path="/signup">
              <SignUpPage />
            </Route>
            <Route key="verification" path="/verify">
              <VerifyCodePage />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
