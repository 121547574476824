import React from "react";

const PaymentGateway = () => {
  return (
    <section id='payment-gateway'>
      <div>
        <h2 id='connecting-a-new-stripe-account' className='p-4'>
          <strong>Connecting a new Stripe account</strong>
        </h2>

        <div className='stripe-wrapper'>
          <h3 id='email' className='stripe-step'>
            <strong>1. Email</strong>
          </h3>
          <p>Click on the url sent to you to start Stripe onboarding process</p>
          <div className='stripe-image-wrapper '>
            <img src='/images/stripe-email.png' alt='email' />
          </div>
        </div>

        <div className='stripe-wrapper'>
          <h3 id='2-password' className='stripe-step'>
            <strong>2. Password</strong>
          </h3>
          <p>
            In the second step, create a password for your new account and click{" "}
            <strong>Continue</strong>.
          </p>
          <div className='stripe-image-wrapper '>
            <img src='/images/stripe-password.png' alt='password' />
          </div>
        </div>

        <div className='stripe-wrapper'>
          <h3 id='3-two-step-account-verification' className='stripe-step'>
            <strong>3. Two-step account verification</strong>
          </h3>
          <p>Stripe will prompt you to setup 2-step authentication</p>

          <div className='stripe-image-wrapper '>
            <img
              src='/images/stripe-two-step.png'
              alt='two-step-verification'
            />
          </div>
        </div>

        <div className='stripe-wrapper'>
          <h3 id='4-emergency-backup-code' className='stripe-step'>
            <strong>4. Emergency backup code</strong>
          </h3>
          <p>
            Once your mobile number is verified, you will be presented with a
            page with an emergency backup code that you should record somewhere
            safe for the future.
          </p>
          <div className='stripe-image-wrapper '>
            <img src='/images/stripe-backup-code.png' alt='backup-code' />
          </div>
        </div>

        <div className='stripe-wrapper'>
          <h3 id='5-business-location' className='stripe-step'>
            <strong>5. Location & Business Type</strong>
          </h3>
          <p>
            On this page, you are required to provide a business location and
            type for your account.
          </p>
          <div className='stripe-image-wrapper '>
            <img src='/images/stripe-location.png' alt='location' />
          </div>
        </div>

        <div className='stripe-wrapper'>
          <h3 id='6-verify-your-personal-details' className='stripe-step'>
            <strong>6. Add Business Details</strong>
          </h3>
          <p>
            Add your personal details on this page to verify your identity with
            Stripe.
          </p>

          <div className='stripe-image-wrapper '>
            <img src='/images/stripe-business.png' alt='business' />
          </div>
        </div>

        <div className='stripe-wrapper'>
          <h3 id='7-professional-details' className='stripe-step'>
            <strong>7. Bank Details</strong>
          </h3>
          <p>
            The next step is to add your bank details to set up payouts for your
            account. Stripe will transfer the funds you receive from member
            subscriptions to the bank account you set up in this step.
          </p>
          <div className='stripe-image-wrapper'>
            <img src='/images/stripe-payout.png' alt='payout' />
          </div>
        </div>

        <div className='stripe-wrapper'>
          <h3 id='9-public-details' className='stripe-step'>
            <strong>9. Add Public Details</strong>
          </h3>
          <p>
            Add a statement descriptors and customer support contact on this
            step that will appear on your member’s bank or credit card
            statements!
          </p>
          <div className='stripe-image-wrapper'>
            <img src='/images/stripe-customers.png' alt='customers' />
          </div>
        </div>

        <div className='stripe-wrapper'>
          <h3 id='10-review-details' className='stripe-step'>
            <strong>10. Review & Submit</strong>
          </h3>
          <p>
            This is the final step of the connection process. Review all your
            details and, if required, make changes to the details by clicking{" "}
            <strong>Edit</strong> ✏️ beside the section to update it.
          </p>
          <div className='stripe-image-wrapper'>
            <img src='/images/stripe-review.png' alt='review' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentGateway;
