import { Link } from "react-router-dom";

export function Terms() {
  return (
    <div className="col-12 py-5">
      <div className="row">
        <div className="col-md-12 col-sm-12  text-left">
          <h2
            style={{
              color: "#2E2E2D",
            }}
          >
            Terms of Use
          </h2>
          <h4
            className="mb-4"
            style={{
              color: "#2E2E2D",
            }}
          >
            Agreement for application, License and privacy
          </h4>
          <p className="pb-5">
            We are ApricotSystems Canada, based in Ontario, Canada
            (“ApricotSystems,” “our,” “us” or “we”). Your access, installation
            and use of our “ApricotSystems” software, services and websites
            (collectively, the “Services”), is subject to the terms and
            conditions set forth in these Terms of Service, our Privacy Policy,
            our Client License Agreement (“CLA”), and in any other
            (“ApricotSystems,” “our,” “us” or “we”) agreements that relate to
            the Services (collectively, “Agreement”).
            <br />
            <br />
            BY USING THE SERVICES YOU ARE DEEMED TO HAVE READ AND ARE INDICATING
            YOUR ACCEPTANCE OF, YOUR AGREEMENT TO BE BOUND BY, AND YOUR LEGAL
            CAPACITY TO BE BOUND BY, THE TERMS AND CONDITIONS OF THESE TERMS OF
            SERVICE WHICH SHALL GOVERN YOUR ACCESS AND USE OF THE SERVICES.
            <br />
            <br />
            IF YOU DO NOT AGREE WITH ONE OR MORE OF THESE TERMS OF SERVICE YOU
            MAY NOT ACCESS OR USE THE SERVICES AND MUST EXIT THE SERVICES.
            <br />
            <br />
            In consideration of the mutual promises and agreements contained in
            these Terms of Service and other good and valuable consideration
            (the receipt and sufficiency of which are hereby acknowledged), you
            and (“ApricotSystems,” “our,” “us” or “we”) agree as follows:
          </p>
        </div>
        <div className="col-md-12 col-sm-12">
          <Link to={`/signup/congrats`}>
            <input className="btn  px-3 button" type="submit" value="Accept" />
          </Link>
        </div>
      </div>
    </div>
  );
}
