import * as axios from "axios";

export const apiRegister = async (data) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/register`, data);
};

export const getChurchDetails = async (id) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/church/${id}`);
};

export const verifyCode = async (data) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/verify`, data);
};

export const resendVerificationCode = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/resendVerificationEmail`,
    data
  );
};
