function FooterSection() {
  return (
    <footer
      style={{ backgroundImage: 'url("/images/bg-dark.png")' }}
      id="footer"
      className="py-1"
    >
      <div className="container flex-row text-left text-white">
        <div className="app-info d-flex align-items-center py-1">
          <div
            style={{
              padding: 20,
              borderRadius: 50,
              position: "absolute",
              right: "10vh",
              backgroundColor: "#424242",
              border: "4px solid white"
            }}
          >
            <img
              style={{
                height: "35px",
                maxWidth: "unset"
              }}
              src="/images/icons/apricot_logo.png"
              alt="logo"
            />
          </div>
          <div className="d-flex justify-content-between flex-wrap max-width-80">
            <a
              href="/terms_and_conditions"
              className="flex-1 mx-4 text-white cursor-pointer"
            >
              Terms of Use
            </a>
            <a
              href="/privacy-policy"
              className="flex-1 mx-4 text-white cursor-pointer"
            >
              Privacy Policy
            </a>
            <a
              href="/license-agreement"
              className="flex-1 mx-4 text-white cursor-pointer"
            >
              License Agreement
            </a>
            <a
              className="flex-1 mx-4 text-white cursor-pointer"
              href="https://chrcuhplan-media-space.nyc3.digitaloceanspaces.com/ChurchPlan%20Privacy%20Policy.pdf"
              target="_blank"
              rel="noreferrer"
            >
              @Apricot Systems
            </a>
            <span className="mx-2 my-1 font-10 hint" style={{ fontSize: 10 }}>
              0.0.5
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterSection;
