import LandingHeader from "../components/header";
import FooterSection from "../components/footer-section";
import { Switch, Route } from "react-router-dom";
import { CreateAccount } from "../components/blocks/create-account";

import { Terms } from "./terms";
import { Congrats } from "./congrats";
import { Verify } from "../components/blocks/verifiy-code";

function SignUpPage() {
  return (
    <>
      <LandingHeader showLinks={false} />
      <div className="main-content">
        <div className="row signup-content">
          <Switch>
            <Route path="/signup/verify">
              <CreateAccount />
              <Verify />
            </Route>
            <Route path="/signup/terms">
              <Terms />
            </Route>
            <Route path="/signup/congrats">
              <Congrats />
            </Route>
            <Route exact path="/signup">
              <CreateAccount />
            </Route>
          </Switch>
        </div>
      </div>
      <FooterSection />
    </>
  );
}

export default SignUpPage;
