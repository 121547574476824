function DownloadApp() {
  return (
    <section id='home' className='hero-area text-black py-4'>
      {/* Overview section */}
      <div id='download-app' className='py-5'>
        <div className='container py-3'>
          <h1
            className='fadeInLeft my-5 d-inline-flex flex-column flex-md-row text-center text-md-start responsive-heading'
            data-wow-delay='.4s'
          >
            Download our Church <span className='text-primary'>Plan</span>{" "}
            Mobile App
          </h1>

          <div className='d-flex justify-content-between'>
            <div className='ios-image-wrapper'>
              <a
                href='https://apps.apple.com/ca/app/churchplan/id1566418629'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src='/images/app-store.png' alt='ios' />
              </a>
            </div>
            <div className='android-image-wrapper'>
              <a
                href='https://play.google.com/store/apps/details?id=com.churchplan&hl=en_US'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src='/images/google-play.png' alt='android' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DownloadApp;
