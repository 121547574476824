export function Congrats() {
  return (
    <div
      className="col-12"
      style={{
        paddingTop: "10rem",
      }}
    >
      <h3 className="text-primary">Congratulations!</h3>
      <h5 className="my-4">Your Account has been successfully created</h5>
      <p className="pb-4 text-black">
        Please download Church<span className="text-primary">Plan</span> App
        <br />
        to start using your account
      </p>
      <div
        className="container-fluid"
        style={{
          maxWidth: "24rem",
        }}
      >
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-6">
            <a
              href="https://apps.apple.com/us/app/churchplan/id1566418629?itsct=apps_box_badge&amp;itscg=30200"
              style={{
                width: "160px",
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1636761600&h=d4339bf0d19b0325f888c96acffe9205"
                alt="Download on the App Store"
              />
            </a>
          </div>
          <div className="col-sm-12 col-md-6">
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.googlequicksearchbox&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              style={{
                width: "160px",
              }}
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
