function ComingSoon() {
  return (
    <section id="coming-page" className="hero-area text-black py-4">
      {/* Coming section */}
      <div id="contact section" className="py-5">
        <h1 className="margin-auto">Coming Soon..</h1>
      </div>
    </section>
  );
}

export default ComingSoon;
